.header {
    background-color: rgb(0, 90, 229);
    color: white;
}

.header a {
    color: white;
}

.content {
    padding-bottom: 175px;
}

.secondary-background {
    background-color: rgb(225, 231, 239);
}
